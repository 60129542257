import React from "react";
import { timeFormat } from "../../util";
import { memoize } from "lodash";
import styled from "styled-components"
import type { FramundanItem } from "../../framundan";

const transChan = memoize(function (channel: string) {
  switch (channel) {
    case "ruv1":
      return "RÚV";
    case "ruv2":
      return "RÚV 2";
    case "siminnsport":
      return "Síminn 1";
    case "siminnsport2":
      return "Síminn 2";
    case "siminnsport3":
      return "Síminn 3";
    case "siminnsport4":
      return "Síminn 4";
    case "sjonvarpsimans":
      return "Sjónvarp Símans";
    case "vodafone":
      return "Vodafone Sport";
    default:
      return channel;
  }
});

const getLogo = memoize(function (channel: string) {
  const abs = process.env.STATIC_URL || "";
  switch (channel.toLowerCase().substr(0, 3)) {
    case "ruv":
      return abs + "ruv.png";
    case "sim":
    case "sjo":
      return abs + "siminn.png";
    case "stö":
    case "bes":
      return abs + "stod2.png";
    case "via":
      return abs + "viaplay.png";
    case "vod":
      return abs + "vodafone.webp";
  }
});

type Props = {
  item: FramundanItem
}

export default function Framundan({ item }: Props) {

  return (
    <Wrapper
      key={item.key}
      $spurs={item.title.indexOf("ottenham") > -1}
    >
      <ChannelLogo>
        <img
          src={getLogo(item.channel)}
          alt={transChan(item.channel)}
        />
      </ChannelLogo>
      <Event>
        <EventTitle>{item.title}</EventTitle>
        <EventDescription>{item.description}</EventDescription>
      </Event>
      <WhenAndWhere>
        <Channel>{transChan(item.channel)}</Channel>
        <div>🕒 {timeFormat(item.date)}</div>
      </WhenAndWhere>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $spurs: boolean }>`
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;

  color: ${p => p.$spurs && "var(--text-highlight)"};

  &:nth-child(even) {
    background-color: var(--table-bg-even);
  }
  &:nth-child(odd) {
    background-color: var(--table-bg-odd);
  }
`

const Event = styled.div`
  padding-inline: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`

const ChannelLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;

  img {
    max-width: 75px;
  }

  @media(max-width: 700px) {
    img {
      max-width: 55px;
    }
  }
`

const WhenAndWhere = styled.div`
  text-align: right;
  min-width: fit-content;
  padding-right: 10px;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const EventTitle = styled.span`
  text-align: left;
  margin: 5px 0;
  font-size: 1.25rem;
  font-weight: 600;
`
const EventDescription = styled.span`
  text-align: left;
  font-weight: 400;
  font-size: 1rem;
`

const Channel = styled.div`
  margin: 5px 0;
`