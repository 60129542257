import React from "react";
import Event from "../Event/Event";
import styled from "styled-components"
import type { FramundanItem } from "../../framundan";

type Props = {
  title: string,
  framundan: { [day: string]: FramundanItem[] }
}

export default function Framundan({ framundan, title }: Props) {
  return (
    <Wrapper>
      <Title>
        {title}
      </Title>
      {framundan[title].map((item, i) => (
        <Event key={item.key} item={item} />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-weight: 600;
  background-color: var(--bg-color);
`

const Title = styled.p`
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
  position: sticky;
  top: 0;
  background-color: var(--bg-color);
  text-transform: capitalize;
  padding-block: 8px;
  border-bottom: 2px solid #009879;
`