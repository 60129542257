import { groupBy } from "lodash";
import { dateFormat } from "./util";

export type FramundanItem = {
  key: string;
  date: Date;
  title: string;
  channel: string;
  description: string;
  provider: Provider;
};

const _items: Map<Provider, FramundanItem[]> = new Map();

export default async function getFramundan(
  filters: Provider[]
): Promise<{ [day: string]: FramundanItem[] }> {
  if (_items.size < 1) {
    await fetchAll();
  }
  let items: FramundanItem[] = filters.flatMap(
    (provider) => _items.get(provider) || []
  );
  items = items.sort((a, b) => a.date.getTime() - b.date.getTime());
  return groupBy(items, (it) => dateFormat(it.date));
}

async function fetchAll(): Promise<void> {
  const response = await fetch(process.env.REACT_APP_DATA_URL || "");
  const data = await response.json();
  for (const key in data) {
    _items.set(
      // @ts-ignore
      key,
      // @ts-ignore
      data[key].map((x) => ({
        key: x.key,
        date: new Date(x.date),
        title: x.title,
        channel: x.channel,
        description: x.description,
        provider: x.provider,
      }))
    );
  }
}
