import React from "react"
import styled from "styled-components"

type Item = [Provider, string];

const CHANNELS: Item[] = [
  ["ruv", "RÚV"],
  ["stod2", "Stöð 2"],
  ["siminn", "Síminn"],
  ["viaplay", "Viaplay"],
];

interface Props {
  active: Provider[],
  onUpdate: (filters: Provider[]) => void,
}

export default function Filters({ active, onUpdate }: Props) {
  return <Wrapper>
    {CHANNELS.map(([key, name]) =>
      <FilterButton
        key={key}
        $active={active.includes(key)}
        onClick={() => {
          const filters = new Set(active);
          if (filters.has(key)) {
            filters.delete(key);
          } else {
            filters.add(key);
          }
          onUpdate(Array.from(filters));
        }}
      >
        {name}
      </FilterButton>
    )}
  </Wrapper>
}

const Wrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`

const FilterButton = styled.button<{$active: boolean}>`
  background-color: var(--button-color);
  padding: 0.4em 0.1em;
  border-radius: 0.5em;
  width: 80px;
  margin: 0.6em 0.1em;
  font-weight: bold;
  border-width: 0;

  &:hover {
    box-shadow: inset 0 0 0 2px var(--button-border-color);
  }

  background-color: ${p => !p.$active && "var(--button-color-inactive)"};
  color: ${p => !p.$active && "var(--text-color)"};
`