import React, { useEffect, useState } from "react";
import getFramundan from "../../framundan";
import styled from "styled-components";
import Day from "../Day/Day";
import type { FramundanItem } from "../../framundan";

type Props = {
  filters: Provider[]
}

export default function Framundan({ filters }: Props) {
  const [framundan, setFramundan] = useState<{
    [day: string]: FramundanItem[];
  }>({});

  useEffect(() => {
    getFramundan(filters).then((items) => setFramundan(items));
  }, [filters]);

  return (
    <Wrapper>
      {Object.keys(framundan).map((day) => (
        <Day key={day} title={day} framundan={framundan} />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 950px;
  text-align: left;
  padding: 0 8px;
  justify-content: center;
`