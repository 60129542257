import React, { useState } from 'react';
import Framundan from '../Framundan/Framundan';
import Filters from "../Filters/Filters";
import styled from 'styled-components';

function App() {
  const [filters, setFilters] = useState<Provider[]>([
    "ruv",
    "siminn",
    "stod2",
    "viaplay",
    "vodafone",
  ]);

  const onFilterUpdate = React.useCallback(
    (newFilters: Provider[]) => setFilters(newFilters),
    []
  );

  return (
    <Wrapper>
      <Filters active={filters} onUpdate={onFilterUpdate} />
      <Framundan filters={filters} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  text-align: center;
  background-color: var(--bg-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: sans-serif;
`

export default App;
