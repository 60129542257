import "@formatjs/intl-datetimeformat/polyfill-force"
import "@formatjs/intl-datetimeformat/locale-data/is"

export function dateFormat(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };

  return date.toLocaleDateString('is-IS', options);
}

export function timeFormat(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    second: undefined
  };
  return date.toLocaleTimeString('is-IS', options)
}